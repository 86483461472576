<script setup lang="ts">
// This componet is only override because of the login path.
// TODO: make it possible to define an auth route per app, or some other kind of override.

const { t } = useI18n({ useScope: 'local' })
</script>

<template>
  <BaseButton
    color="primary"
    to="/inloggen"
  >
    {{ t('signIn') }}
  </BaseButton>
</template>

<i18n lang="json">
{
  "en": {
    "signIn": "Sign in"
  },
  "nl": {
    "signIn": "Inloggen"
  }
}
</i18n>
